import React from "react";
import { Tab, Tabs, Button, Stack } from "@mui/material";
import { Order } from "@types";

import ClientInformationForm from "./ClientInformationForm";
import OrderArticles from "./OrderArticles";
import Appointment from "./Appointment";

import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import NavigateBeforeIcon from "@mui/icons-material/NavigateBefore";

interface TabNavigationProps {
  currentActiveTab: number;
  setActiveTab: (value: number) => void;
  isFloorValid: boolean;
}

const TabNavigation = ({
  currentActiveTab,
  setActiveTab,
  isFloorValid,
}: TabNavigationProps) => {
  return (
    <div className="tab-navigation">
      <div className="tab-navigation_container">
        <span className="tab-navigation__prev">
          <Button
            disabled={currentActiveTab == 0}
            variant="outlined"
            color="primary"
            startIcon={<NavigateBeforeIcon />}
            onClick={() => setActiveTab(currentActiveTab - 1)}
          >
            précédent
          </Button>
        </span>
        <span className="tab-navigation__next">
          <Button
            disabled={currentActiveTab == 2 || !isFloorValid}
            variant="outlined"
            color="primary"
            endIcon={<NavigateNextIcon fontSize="small" />}
            onClick={() => setActiveTab(currentActiveTab + 1)}
          >
            suivant
          </Button>
        </span>
      </div>
    </div>
  );
};

// @TODO Create a file that contains types/interfaces
interface ScheduleDeliveryTabsProps {
  order: Order;
}

interface ScheduleDeliveryTabPanelProps {
  children?: React.ReactNode;
  active: boolean;
}

const ScheduleDeliveryTabPanel = ({
  children,
  active,
}: ScheduleDeliveryTabPanelProps) => {
  return (
    <div className="schedule-delivery-tab-panel">
      {active && (
        <div className="schedule-delivery-tab-panel__content">{children}</div>
      )}
    </div>
  );
};

const ScheduleDeliveryTabs = ({ order }: ScheduleDeliveryTabsProps) => {
  const [currentActiveTab, setCurrentActiveTab] = React.useState<number>(0);
  const [isFloorValid, setIsFloorValid] = React.useState(false);
  const handleChange = (event: React.SyntheticEvent, newActiveTab: number) => {
    setCurrentActiveTab(newActiveTab);
  };

  return (
    <div className="schedule-delivery-tabs">
      <Tabs
        centered
        value={currentActiveTab}
        indicatorColor="secondary"
        onChange={handleChange}
      >
        <Tab label="Coordonnées" />
        <Tab label="Commande" disabled={!isFloorValid} />
        <Tab label="Rendez-vous" disabled={!isFloorValid} />
      </Tabs>
      <ScheduleDeliveryTabPanel active={currentActiveTab === 0}>
        <ClientInformationForm
          client={order.client}
          isFloorValid={isFloorValid}
          setIsFloorValid={setIsFloorValid}
        />
      </ScheduleDeliveryTabPanel>
      <ScheduleDeliveryTabPanel active={currentActiveTab === 1}>
        <OrderArticles articles={order.articles} />
      </ScheduleDeliveryTabPanel>
      <ScheduleDeliveryTabPanel active={currentActiveTab === 2}>
        <Appointment order={order} />
      </ScheduleDeliveryTabPanel>
      <TabNavigation
        setActiveTab={setCurrentActiveTab}
        currentActiveTab={currentActiveTab}
        isFloorValid={isFloorValid}
      />
    </div>
  );
};

export default ScheduleDeliveryTabs;
