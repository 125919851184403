import React from "react";
import { useMutation, useQuery } from "@apollo/client";
import { useFormik } from "formik";
import { toast } from "react-toastify";
import TextField from "@mui/material/TextField";
import { OrderClient } from "@types";
import LoadingButton from "@mui/lab/LoadingButton";
import { CircularProgress } from "@mui/material";
import UpdateClientOrderClientMutation from "./UpdateClientOrderClientMutation.gql";
import ClientPortalConfigQuery from "pages/Delivery/ClientPortalConfigQuery.gql";
import validationSchema from "./validationSchema";
import PostalCodeAndCityAutoComplete from "./PostalCodeAndCityAutoComplete";
import AlertDialog from "components/AlertDialog";

export interface ClientInformationFormState {
  firstname: string;
  lastname: string;
  address: string;
  address_addition: string;
  postal_code_city: string;
  floor: number | string;
  email: string;
  phone: string;
}

interface ClientInformationFormProps {
  client: OrderClient;
  isFloorValid: boolean;
  setIsFloorValid: (value: boolean) => void;
}

const ClientInformationForm = ({
  client,
  isFloorValid,
  setIsFloorValid,
}: ClientInformationFormProps) => {
  const [openDialog, setOpenDialog] = React.useState<boolean>(false);
  const { loading: configLoading, data: clientPortalConfigData } = useQuery(
    ClientPortalConfigQuery
  );

  const formLocked = React.useMemo(() => {
    return !clientPortalConfigData?.clientPortalConfig?.contractorConfig
      ?.is_client_info_modifiable_in_client_portal;
  }, [
    clientPortalConfigData?.clientPortalConfig?.contractorConfig
      ?.is_client_info_modifiable_in_client_portal,
  ]);

  const initialValues: ClientInformationFormState =
    React.useMemo<ClientInformationFormState>(() => {
      return {
        firstname: client.firstname,
        lastname: client.lastname,
        address: client.address,
        address_addition: client.address_addition,
        postal_code_city: `${client.postal_code} - ${client.city}`,
        floor: client.floor,
        email: client.email,
        phone: client.phone,
      };
    }, [client]);

  const [updateClientOrderClient, { loading }] = useMutation(
    UpdateClientOrderClientMutation,
    {
      onCompleted: () =>
        toast.success("Vos informations ont bien été enregistrées"),
      onError: () =>
        toast.error(
          "Une erreur est survenue, vos informations n'ont pas été mises à jours"
        ),
    }
  );

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: (formValues) => {
      const { postal_code_city, ...clientOrderClient } = formValues;
      const [postal_code, city] = postal_code_city.split(" - ");

      updateClientOrderClient({
        variables: {
          clientOrderClient: { ...clientOrderClient, postal_code, city },
        },
      });
    },
  });
  React.useEffect(() => {
    const isValid = initialValues.floor !== null;
    setIsFloorValid(isValid);
  }, [initialValues.floor, setIsFloorValid]);

  const handleSubmitForm = (event: any) => {
    event.preventDefault();

    if (formik.values.postal_code_city !== initialValues.postal_code_city) {
      setOpenDialog(true);
    } else {
      formik.handleSubmit();
    }
  };

  const isFormDataChanged = React.useMemo<boolean>(() => {
    return formik.dirty;
  }, [formik.dirty]);

  if (configLoading) {
    return <CircularProgress />;
  }

  return (
    <>
      <form className="client-information-form" onSubmit={handleSubmitForm}>
        <div className="client-information-form__fields">
          <TextField
            required
            fullWidth
            label="Nom"
            variant="outlined"
            name="lastname"
            value={formik.values.lastname}
            onChange={formik.handleChange}
            error={formik.touched.lastname && Boolean(formik.errors.lastname)}
            helperText={formik.touched.lastname && formik.errors.lastname}
            disabled={true}
          />
          <TextField
            required
            fullWidth
            label="Prénom"
            variant="outlined"
            name="firstname"
            value={formik.values.firstname}
            onChange={formik.handleChange}
            error={formik.touched.firstname && Boolean(formik.errors.firstname)}
            helperText={formik.touched.firstname && formik.errors.firstname}
            disabled={true}
          />
          <TextField
            required
            fullWidth
            label="Adresse de livraison"
            variant="outlined"
            name="address"
            value={formik.values.address}
            onChange={formik.handleChange}
            error={formik.touched.address && Boolean(formik.errors.address)}
            helperText={formik.touched.address && formik.errors.address}
            disabled={formLocked}
          />
          <TextField
            fullWidth
            label="Complément d'adresse"
            variant="outlined"
            name="address_addition"
            value={formik.values.address_addition}
            onChange={formik.handleChange}
            disabled={formLocked}
          />
          <PostalCodeAndCityAutoComplete
            value={formik.values.postal_code_city}
            onChange={(_, value: string | null) =>
              formik.setFieldValue("postal_code_city", value)
            }
            error={
              formik.touched.postal_code_city &&
              Boolean(formik.errors.postal_code_city)
            }
            helperText={
              formik.touched.postal_code_city && formik.errors.postal_code_city
            }
            disabled={formLocked}
          />
          <TextField
            required
            fullWidth
            label="Étage"
            variant="outlined"
            type="number"
            name="floor"
            value={formik.values.floor}
            onChange={formik.handleChange}
            error={
              (formik.touched.floor && Boolean(formik.errors.floor)) ||
              (!formik.values.floor && formik.values.floor !== 0)
            }
            helperText={formik.touched.floor && formik.errors.floor}
            disabled={formLocked}
          />
          <TextField
            required
            fullWidth
            label="N° de tél"
            variant="outlined"
            name="phone"
            value={formik.values.phone}
            onChange={formik.handleChange}
            error={formik.touched.phone && Boolean(formik.errors.phone)}
            helperText={formik.touched.phone && formik.errors.phone}
            disabled={formLocked}
          />
          <TextField
            required
            fullWidth
            label="Email"
            variant="outlined"
            name="email"
            value={formik.values.email}
            onChange={formik.handleChange}
            error={formik.touched.email && Boolean(formik.errors.email)}
            helperText={formik.touched.email && formik.errors.email}
            disabled={formLocked}
          />
        </div>
        {!formLocked && (
          <div className="client-information-form__action">
            <LoadingButton
              type="submit"
              loading={loading}
              variant="contained"
              size="large"
              disabled={!isFormDataChanged}
            >
              Enregistrer les modifications
            </LoadingButton>
          </div>
        )}
      </form>
      <AlertDialog
        handleClose={() => setOpenDialog(false)}
        open={openDialog}
        callback={formik.handleSubmit}
        title="Attention !"
        message="Si vous modifiez le code postal le créneau choisi sera réinitialisé et devra être sélectionné de nouveau. Ignorez cette alerte si vous n'avez pas encore sélectionné de créneau."
        appearence="alert"
      />
    </>
  );
};

export default ClientInformationForm;
